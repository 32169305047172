<template>
  <div class="tracks">
    <div class="search-bar">
      <van-search
        v-model="keyword"
        class="round"
        show-action
        shape="round"
        :placeholder="$t('Tracks.dp997c')"
        @search="onSearch"
        @clear="onClear"
      >
        <div slot="action" @click="onSearch">{{ $t('搜索') }}</div>
      </van-search>
    </div>
    <main class="card" v-if="tracks.length">
      <div class="mb-x">{{ $t('订单轨迹详情') }}</div>
      <div class="f-12 mb-x">{{ $t('运单号') }}：{{ orderNo }}</div>
      <timeline>
        <timeline-title>
          <div>
            <span class="addr">[{{ tracks[0].addr }}]</span>
            {{ tracks[0].context }}
          </div>
          <div class="track-en">{{ tracks[0].en_content }}</div>
          <div class="f-12 time">{{ tracks[0].time }}</div>
        </timeline-title>
        <timeline-item v-for="(item, index) in tracks.slice(1)" :key="index">
          <div>
            <span class="addr">[{{ tracks[0].addr }}]</span> {{ item.context }}
          </div>
          <div class="track-en">{{ item.en_content }}</div>
          <div class="time f-12">{{ item.time }}</div>
        </timeline-item>
      </timeline>
    </main>
    <div class="card set-center" v-else>
      <img :src="emptyImg" class="track-no-img" />
      <div>{{ $t('暂无轨迹，请输入运单号查询') }}</div>
    </div>
  </div>
</template>
<script>
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline';
import { getTracks } from '@/api/deliver.js';
export default {
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
  },
  data() {
    return {
      keyword: '',
      orderNo: '',
      tracks: [],
      emptyImg: require('../assets/images/noinfo.png'),
    };
  },
  mounted() {
    if (this.$route.query != null && this.$route.query.key != null) {
      let key = this.$route.query.key;
      if (key == null || key != '') {
        this.keyword = key;
        this.onSearch();
      }
    }
  },
  methods: {
    onSearch() {
      getTracks({ orderNo: this.keyword }).then(res => {
        this.orderNo = this.keyword;
        this.tracks = res.data;
      });
    },
    onClear() {
      this.keyword = '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.mb-x {
  margin-bottom: rem(40px);
}

/deep/ .timeline-title-circle {
  background-color: $theme-color;
}
/deep/ .timeline-item {
  border-bottom: 1px solid #f6f6f6;
}
.search-bar {
  padding: rem(40px) rem(20px);
  background-color: #fff;
}
.card {
  padding: rem(30px) rem(40px);
  background-color: #fff;
  margin-top: rem(10px);
  .track-no-img {
    width: rem(169px);
    margin: rem(10px);
  }
  .addr {
    color: #4588ec;
  }
}
.time {
  color: #666;
  font-size: 12px;
  margin-top: rem(20px);
}
.track-en {
  color: #979797;
}
</style>
